import React, { Component} from "react"
import { graphql, Link } from "gatsby"
import loadable from '@loadable/component'

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import CopyBlock from "../components/Copy/CopyBlock"

const FacebookComments = loadable(() => import('../components/Utilities/FacebookComments'))

function VoteAndDiscuss(props) {

  return (
    <Layout>
      <SEO
        title={"Vote and Discuss - Messi vs Ronaldo, WHO is the best?"}
        description={`So WHO is the best? Messi or Ronaldo? Cast your vote and discuss the debate that has transcended football.`}
        canonicalPath={`/vote-and-discuss/`}
      />

      <h1>Vote and Discuss <span className="sr-only">Messi vs Ronaldo</span></h1>

      

      <div className="md:mt-16 xl:mt-24">

        <CopyBlock>
          <h2>Cast your vote!</h2>
          <iframe loading="lazy" title="Vote Now" src="https://www.poll-maker.com/frame2776072xd2006e3e-81" seamless="seamless" className="qp_iframe w-full h-48 border-0 bg-transparent"></iframe>
        </CopyBlock>

        <CopyBlock bg="light">
          <h2>Messi vs Ronaldo Discussion</h2>
          <FacebookComments />
        </CopyBlock>

      </div>

      <LastUpdated type="All Time Stats" />
      
    </Layout>
  )
}

export default VoteAndDiscuss
